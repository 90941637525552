/**
 * @name: Index ( New Bank Statement) 
 * @description: Index page for the user interaction in which they can add a new bank statement this will communicate with backend to send upload and process the csv files. Users will be able to then add these to jobs. 
 * @author: Fatlum Hasaj
 * 
 */
import { useState } from "react";
import Stepper from "./components/Stepper";
import { UseContextProvider } from "./contexts/StepperContext";
import UploadCSV from "./components/steps/UploadCSV";
import Processing from "./components/steps/Processing";
import Confirmation from "./components/steps/Confirmation";

import Card from "components/card";

const NewUser = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [FirstStage , setFistStage] = useState<any>([]);
  const [SecondStage , setSecondStage] = useState<boolean>();
  const [progress, setProgress ] = useState<number>(0);

  const HandleStepOneData = (data: any) => {
  // Send data back to the backend and then get the response of confirmation and then set the progress bar.

    setFistStage(data);
    if (!data) {
      setProgress(0);
    }else{
      setTimeout(() => {
        setProgress(10);
      }, 1000);
      setTimeout(() => {
        setProgress(25);
      }, 2000);
      setTimeout(() => {
        setProgress(50);
      }, 3000);
      setTimeout(() => {
        setProgress(75);
      }, 4000);
      setTimeout(() => {
        setProgress(100);
      }, 5000);
    }
  };


  const handleStepTwoData = (data:any) => {

    //Get back from the backend when the processing is complete and then set the second stage to true which will then if there is any parts that need to be confirmed to confirm them in the next page.

    setTimeout(() => {
      setSecondStage(true);
    }, 8000);
  };


  const handleStepThreeData = (data:any) => {
   // Send the data back to the backend to confirm the data and then get the response of the confirmation and then set the progress bar.
  }

  const steps = [
    { stepNo: 1, name: "Upload CSV" },
    { stepNo: 2, name: "Processing" },
    { stepNo: 3, name: "Confirmation" },
  ];
  const displayStep = (step: {
    stepNo: number;
    name: string;
    highlighted?: boolean;
    selected?: boolean;
    completed?: boolean;
  }) => {
    switch (step.stepNo) {
      case 1:
        return <UploadCSV 
        sendDataToStepOne={HandleStepOneData}
        handleClick={handleClick}
        currentStep={currentStep}
        sendPrecent={progress}
        steps={steps}/>;
      case 2:
        return <Processing 
        dataComplete={handleStepTwoData}
        handleClick={handleClick}
        currentStep={currentStep}
        steps={steps}
        />;
      case 3:
        return <Confirmation 
        Data1={FirstStage}
        Data2={SecondStage}
        handleClick={handleClick}
        currentStep={currentStep}
        steps={steps}
        />;
      default:
    }
  };

  const handleClick = (direction: string) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };
  return (
    <div className="mt-3 h-full w-full">
      <div className="h-[350px] w-full rounded-md bg-gradient-to-br from-brand-400 to-brand-600 md:h-[390px]" />
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
        <div className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]">
          <Stepper
            action={setCurrentStep}
            steps={steps}
            currentStep={currentStep}
          />
        </div>
        <Card extra={"h-full mx-auto pb-3"}>
          <div className="rounded-[20px]">
            <UseContextProvider>
              {displayStep(steps[currentStep - 1])}
            </UseContextProvider>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default NewUser;
