import {useState , useEffect} from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Card from "components/card";
import InputField from "components/fields/InputField";
import InputPasswordField from "components/fields/InputPasswordField";
import ButtonField from "components/fields/ButtonField";
import Centered from "layouts/auth/types/Centered";
import Checkbox from "components/checkbox";

//Other Dependencies
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import secureLocalStorage from 'react-secure-storage';

//Authentications Dependencies
import { useForm, Controller} from 'react-hook-form';
import useAuth from "hooks/useAuth";
import axios from "api/axios"
import BackendRoutes from "api/BackendRoutes/BackendRoutes";

function SignInCenter() {
  
  const { setAuth, persist, setPersist } = useAuth() as any;
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';
  const [id , setID] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const Title = 'Login in to your account';

  const {  control,  handleSubmit,  } = useForm();

  const verifyEmail = async () => { //Verify Email
    setIsLoading(true);
    try {
      const response = await axios.get(
        BackendRoutes.EmailActivationReset + id,
      );
      if(response?.status === 201){
        toast.warning('Account not verified, sent new verification link to your email');
        console.log(response?.data);
        setID(response?.data);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error('Error:' + error);
    }      
  };

  /**
   * Handles the form submission for user login.
   * 
   * @param data - The form data containing the username and password.
   */
  const onSubmit = async (data:any) => { // Login User
    const user = data.username;
    const pwd = data.password;
    setIsLoading(true);
    try {
      const response = await axios.post(
        BackendRoutes.login,
        JSON.stringify({ user, pwd }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );
      if(response?.status === 203){
        setID(response?.data);
        verifyEmail();
      }else{
      //Extract the data from the response
      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      const UserID = response?.data?.currentID;
      const UserFirstName = response?.data?.username;
      const CompanyName = response?.data?.CompanyName;
      const companyID = response?.data?.CompanyID;
      const ProfilePic = response?.data?.ProfilePic;
      setAuth({
            user,
            pwd,
            UserID,
            UserFirstName,
            roles,
            accessToken,
      });
      secureLocalStorage.setItem('compName', CompanyName);
      secureLocalStorage.setItem('cid', companyID);
      secureLocalStorage.setItem('Name', UserFirstName);
      secureLocalStorage.setItem('ProfilePic', ProfilePic);
      setIsLoading(false);
      navigate(from, { replace: true });
    }
    } catch (err:any)  {
      setTimeout(() => {
      setIsLoading(false);
      }, 1000);
      if (!err?.response)  {
      toast.error('Ops there seems to be an error while trying to connect to the server. Please try again later. ');
    } else if (err.response?.status === 400) {
      toast.warning('You need to enter both the Username and Password');
    } else if (err.response?.status === 401) {
      toast.error('Looks like you have the wrong Username');
    } else if (err.response?.status === 406){
      toast.error('Looks like you have entered the wrong password try again or click on forgot password')
    } else {
      toast.error('Login Failed unknown error please contact support@CHNAGEME.com');
    }
    }
  };

  const togglePersist = () => { //Toggle Remember Me
    setPersist((prev:any) => !prev);
  };

  useEffect(() => { //Set Remember Me
    localStorage.setItem('persist', persist);
  }, [persist]);

  return ( //Render
    <Centered
      maincard={
        <Card extra="max-w-[405px] md:max-w-[510px] md:w-[510px] h-max mx-2.5 md:mx-auto mt-[50px] mb-auto py-2.5 shadow-xl px-4 md:!p-[50px] pt-8 md:pt-[50px]">
          <Helmet>  <title>{Title}</title> </Helmet>

          <h3 className="mb-[10px] text-4xl font-bold text-gray-900 dark:text-white">
            Sign In
          </h3>
          <p className="mb-3 ml-1 text-base text-gray-600">
            Enter your username and password to sign in!
          </p>
      <form onSubmit={handleSubmit(onSubmit)}>
              
        {/* Username Field */}
          <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Username*"
                    placeholder="Enter your username"
                    id="text"
                    type="text"
                    onChange={field.onChange}
                    value={field.value}
                  />
                )}
          />

        {/* Password Field */}
          <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <InputPasswordField
                    variant="auth"
                    extra="mb-3"
                    label="Password*"
                    placeholder="Min. 8 characters"
                    id="password"
                    onChange={field.onChange}
                    value={field.value}
                  />
                )}  
          />
          <div className="mt-2 flex items-center justify-between px-2">
              <Checkbox 
              checked={persist} 
              onChange={togglePersist}
              label="Remember me"
              />
            <a
              className="text-sm font-medium text-brand-500 hover:text-brand-500 dark:text-white"
              href=" "
            >
              Forgot password?
            </a>
          </div>
          <div className="flex mt-5">
          <ButtonField
            id="Login"
            Name="Sign In"
            type={"submit"}
            color="clear"
            isLoading={isLoading}
          />
          </div>
      </form>

        </Card>
      }
    />
  );
}

export default SignInCenter;
