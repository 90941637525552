import { toast } from "react-toastify";
import axios from "../api/axios";
import useAuth from "./useAuth";

const useLogout = () => {
  const { setAuth } = useAuth() as any;

  const logout = async (): Promise<void> => {
    setAuth({});
    try {
     await axios('/auth/logout/', { withCredentials: true });
    } catch (err: any) { 
      new Error(err);
      toast.error('An error occurred while logging out. Please contact support@CHANGEME.com');
      return;
    }
  }

  return logout;
}

export default useLogout;