import {
  MdDashboard,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdOutlineDisplaySettings,
  MdOutlineMapsHomeWork,
} from "react-icons/md";

import { BiSolidBank } from "react-icons/bi";
import { FaFileInvoice, FaUser , FaUsersGear } from "react-icons/fa6";
import { LuWarehouse } from "react-icons/lu";
import { IoIosPeople } from "react-icons/io";

// Admin Imports
import DashboardsDefault from "views/main/dashboards/default";
import DashboardsRTLDefault from "views/main/dashboards/rtl";
import DashboardsCarInterface from "views/main/dashboards/carInterface";
import DashboardsSmartHome from "views/main/dashboards/smartHome";

// NFT Imports
import NFTMarketplace from "views/main/nfts/marketplace";
import NFTPage from "views/main/nfts/page";
import NFTCollection from "views/main/nfts/collection";
import NFTProfile from "views/main/nfts/profile";

// Main Imports
import AccountBilling from "views/main/main/account/billing";
import AccountApplications from "views/main/main/account/application";
import AccountInvoice from "views/main/main/account/invoice";
import AccountSettings from "views/main/main/account/settings";
import AccountAllCourses from "views/main/main/account/courses";
import AccountCoursePage from "views/main/main/account/coursePage";

import UserNew from "views/main/main/users/newUser";
import UsersOverview from "views/main/main/users/overview";
import UsersReports from "views/main/main/users/reports";

import ProfileSettings from "views/main/main/profile/settings";
import ProfileOverview from "views/main/main/profile/overview";
import ProfileNewsfeed from "views/main/main/profile/newsfeed";

import ApplicationsKanban from "views/main/main/applications/kanban";
import ApplicationsDataTables from "views/main/main/applications/dataTables";
import ApplicationsCalendar from "views/main/main/applications/calendar";

import EcommerceNewProduct from "views/main/main/ecommerce/newProduct";
import EcommerceProductSettings from "views/main/main/ecommerce/settingsProduct";
import EcommerceProductPage from "views/main/main/ecommerce/productPage";
import EcommerceOrderList from "views/main/main/ecommerce/orderList";
import EcommerceOrderDetails from "views/main/main/ecommerce/orderDetails";
import EcommerceReferrals from "views/main/main/ecommerce/referrals";

// Others
import OthersNotifications from "views/main/main/others/notifications";
//import OthersPricing from 'views/main/main/others/pricing';
import OthersPricing from "views/main/main/others/pricing";
import OthersError from "views/main/main/others/404";
import Buttons from "views/main/main/others/buttons";
import Messages from "views/main/main/others/messages";

// Auth Imports
import ForgotPasswordCentered from "views/auth/forgotPassword/ForgotPasswordCenter";
import ForgotPasswordDefault from "views/auth/forgotPassword/ForgotPasswordDefault";
import LockCentered from "views/auth/lock/LockCenter";
import LockDefault from "views/auth/lock/LockDefault";
import SignInCentered from "views/auth/signIn/SignInCenter";
import SignInDefault from "views/auth/signIn/SignInDefault";
import SignUpCentered from "views/auth/signUp/SignUpCenter";
import SignUpDefault from "views/auth/signUp/SignUpDefault";
import VerificationCentered from "views/auth/verification/VerificationCenter";
import VerificationDefault from "views/auth/verification/VerificationDefault";

/** Admin Routes Import */

//Dashboards
import AdminDashboard from "views/main/dashboards/admin/default";

//Jobs
import NewJob from "views/main/main/Jobs/newJob";
import OverviewJob from "views/main/main/Jobs/overviewJob";
import SettingsJob from "views/main/main/Jobs/settingsJob";

//Customers
import NewCustomer from "views/main/main/customer/newCustomer";
import ViewCustomers from "views/main/main/customer/viewCustomers";

//Suppliers
import NewSupplier from "views/main/main/suppliers/newsuppliers";
import OverviewSupplier from "views/main/main/suppliers/overviewsuppliers";
import SuppliersSettings from "views/main/main/suppliers/supplierssettings";

//Invoice

//Banking
import BankingOverview from "views/main/main/banking/overviewBank";
// import BankingSettings from "views/main/main/banking/settingsBank";
import BankCSV from "views/main/main/banking/bankCSV";
import BankingDashboard from "views/main/main/banking/banking_dashboard";
import NewBankStaments from "views/main/main/banking/newStatement";

/** System Settings **/

import AutoMatch from "views/main/main/settings/AutoMatch"
import NewAutoMatch from "views/main/main/settings/NewAutoMatch"

const routes = [
  // --- Dashboards ---
  // {
  //   name: "Dashboards",
  //   path: "/dashboards",
  //   icon: <MdHome className="text-inherit h-5 w-5" />,
  //   collapse: true,
  //   Role: "all",
  //   items: [
  //     {
  //       name: "Main Dashboard",
  //       layout: "/main",
  //       path: "/dashboards/default",
  //       component: <DashboardsDefault />,
        
  //     },
  //     {
  //       name: "Car Interface",
  //       layout: "/main",
  //       path: "/dashboards/car-interface",
  //       component: <DashboardsCarInterface />,
  //     },
  //     {
  //       name: "Smart Home",
  //       layout: "/main",
  //       path: "/dashboards/smart-home",
  //       component: <DashboardsSmartHome />,
  //     },
  //     {
  //       name: "RTL",
  //       layout: "/rtl",
  //       path: "/dashboards/rtl",
  //       component: <DashboardsRTLDefault />,
  //     },
  //   ],
  // },
  // --- NFTs ---
  // {
  //   name: "NFTs",
  //   path: "/nfts",
  //   icon: <MdOutlineShoppingCart className="text-inherit h-5 w-5" />,
  //   collapse: true,
  //   Role: "main",
  //   items: [
  //     {
  //       name: "Marketplace",
  //       layout: "/main",
  //       path: "/nfts/marketplace",
  //       component: <NFTMarketplace />,
  //       secondary: true,
  //     },
  //     {
  //       name: "Collection",
  //       layout: "/main",
  //       path: "/nfts/collection",
  //       component: <NFTCollection />,
  //       secondary: true,
  //     },
  //     {
  //       name: "NFT Page",
  //       layout: "/main",
  //       path: "/nfts/page",
  //       component: <NFTPage />,
  //       secondary: true,
  //     },
  //     {
  //       name: "Profile",
  //       layout: "/main",
  //       path: "/nfts/profile",
  //       component: <NFTProfile />,
  //       secondary: true,
  //     },
  //   ],
  // },
  // --- Main pages ---
  // {
  //   name: "Main Pages",
  //   path: "/main",
  //   icon: <MdDashboard className="text-inherit h-5 w-5" />,
  //   collapse: true,
  //   Role: "all",
  //   items: [
  //     {
  //       name: "Account",
  //       path: "/main/pages/account",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Billing",
  //           layout: "/main",
  //           path: "/mail/pages/account/billing",
  //           exact: false,
  //           component: <AccountBilling />,
  //         },
  //         {
  //           name: "Application",
  //           layout: "/main/main",
  //           path: "/main/pages/account/application",
  //           exact: false,
  //           component: <AccountApplications />,
  //         },
  //         {
  //           name: "Invoice",
  //           layout: "/main/main",
  //           path: "/main/pages/account/invoice",
  //           exact: false,
  //           component: <AccountInvoice />,
  //         },
  //         {
  //           name: "Settings",
  //           layout: "/main",
  //           path: "/mail/pages/account/settings",
  //           exact: false,
  //           component: <AccountSettings />,
  //         },
  //         {
  //           name: "All Courses",
  //           layout: "/main",
  //           path: "/mail/pages/account/all-courses",
  //           exact: false,
  //           component: <AccountAllCourses />,
  //         },
  //         {
  //           name: "Course Page",
  //           layout: "/main",
  //           path: "/mail/pages/account/course-page",
  //           exact: false,
  //           component: <AccountCoursePage />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Ecommerce",
  //       path: "/ecommerce",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "New Product",
  //           layout: "/main",
  //           path: "/main/ecommerce/new-prodcut",
  //           exact: false,
  //           component: <EcommerceNewProduct />,
  //         },
  //         {
  //           name: "Product Settings",
  //           layout: "/main",
  //           path: "/main/ecommerce/settings",
  //           exact: false,
  //           component: <EcommerceProductSettings />,
  //         },
  //         {
  //           name: "Product Page",
  //           layout: "/main",
  //           path: "/main/ecommerce/page-example",
  //           exact: false,
  //           component: <EcommerceProductPage />,
  //         },
  //         {
  //           name: "Order List",
  //           layout: "/main",
  //           path: "/main/ecommerce/order-list",
  //           exact: false,
  //           component: <EcommerceOrderList />,
  //         },
  //         {
  //           name: "Order Details",
  //           layout: "/main",
  //           path: "/main/ecommerce/order-details",
  //           exact: false,
  //           component: <EcommerceOrderDetails />,
  //         },
  //         {
  //           name: "Referrals",
  //           layout: "/main",
  //           path: "/main/ecommerce/referrals",
  //           exact: false,
  //           component: <EcommerceReferrals />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Users",
  //       path: "/main/users",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "New User",
  //           layout: "/main",
  //           path: "/main/users/new-user",
  //           exact: false,
  //           component: <UserNew />,
  //         },
  //         {
  //           name: "Users Overview",
  //           layout: "/main",
  //           path: "/main/users/users-overview",
  //           exact: false,
  //           component: <UsersOverview />,
  //         },
  //         {
  //           name: "Users Reports",
  //           layout: "/main",
  //           path: "/main/users/users-reports",
  //           exact: false,
  //           component: <UsersReports />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Applications",
  //       path: "/main/applications",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Kanban",
  //           layout: "/main",
  //           path: "/main/applications/kanban",
  //           exact: false,
  //           component: <ApplicationsKanban />,
  //         },
  //         {
  //           name: "Data Tables",
  //           layout: "/main",
  //           path: "/main/applications/data-tables",
  //           exact: false,
  //           component: <ApplicationsDataTables />,
  //         },
  //         {
  //           name: "Calendar",
  //           layout: "/main",
  //           path: "/main/applications/calendar",
  //           exact: false,
  //           component: <ApplicationsCalendar />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Profile",
  //       path: "/main/profile",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Profile Overview",
  //           layout: "/main",
  //           path: "/main/profile/overview",
  //           exact: false,
  //           component: <ProfileOverview />,
  //         },
  //         {
  //           name: "Profile Settings",
  //           layout: "/main",
  //           path: "/main/profile/settings",
  //           exact: false,
  //           component: <ProfileSettings />,
  //         },
  //         {
  //           name: "News Feed",
  //           layout: "/main",
  //           path: "/main/profile/newsfeed",
  //           exact: false,
  //           component: <ProfileNewsfeed />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Others",
  //       path: "/main/others",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Notifications",
  //           layout: "/main",
  //           path: "/main/others/notifications",
  //           exact: false,
  //           component: <OthersNotifications />,
  //         },
  //         {
  //           name: "Pricing",
  //           layout: "/auth",
  //           path: "/main/others/pricing",
  //           exact: false,
  //           component: <OthersPricing />,
  //         },
  //         {
  //           name: "404",
  //           layout: "/admin",
  //           path: "/main/others/404",
  //           exact: false,
  //           component: <OthersError />,
  //         },
  //         {
  //           name: "Buttons",
  //           layout: "/admin",
  //           path: "/main/others/buttons",
  //           exact: false,
  //           component: <Buttons />,
  //         },
  //         {
  //           name: "Messages",
  //           layout: "/admin",
  //           path: "/main/others/messages",
  //           exact: false,
  //           component: <Messages />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // --- Authentication ---
  /** This will be hidden for everyone sidebar however will be visible to all users. */
  {
    name: "Authentication",
    path: "/auth",
    icon: <MdLock className="text-inherit h-5 w-5" />,
    collapse: true,
    Role: "auth",
    items: [
      // --- Sign In ---
      {
        name: "Sign In",
        path: "/sign-in",
        collapse: true,
        items: [
          {
            name: "Default",
            layout: "/auth",
            path: "/sign-in/default",
            component: <SignInDefault />,
          },
          {
            name: "Centered",
            layout: "/auth",
            path: "/sign-in",
            component: <SignInCentered />,
          },
        ],
      },
      // --- Sign Up ---
      {
        name: "Sign Up",
        path: "/sign-up",
        collapse: true,
        items: [
          {
            name: "Default",
            layout: "/auth",
            path: "/sign-up/default",
            component: <SignUpDefault />,
          },
          {
            name: "Centered",
            layout: "/auth",
            path: "/sign-up/centered",
            component: <SignUpCentered />,
          },
        ],
      },
      // --- Verification ---
      {
        name: "Verification",
        path: "/verification",
        collapse: true,
        items: [
          {
            name: "Default",
            layout: "/auth",
            path: "/verification/default",
            component: <VerificationDefault />,
          },
          {
            name: "Centered",
            layout: "/auth",
            path: "/verification/centered",
            component: <VerificationCentered />,
          },
        ],
      },
      // --- Lock ---
      {
        name: "Lock",
        path: "/lock",
        collapse: true,
        items: [
          {
            name: "Default",
            layout: "/auth",
            path: "/lock/default",
            component: <LockDefault />,
          },
          {
            name: "Centered",
            layout: "/auth",
            path: "/lock/centered",
            component: <LockCentered />,
          },
        ],
      },
      // --- Forgot Password ---
      {
        name: "Forgot Password",
        path: "/forgot-password",
        collapse: true,
        items: [
          {
            name: "Default",
            layout: "/auth",
            path: "/forgot-password/default",
            component: <ForgotPasswordDefault />,
          },
          {
            name: "Centered",
            layout: "/auth",
            path: "/forgot-password/centered",
            component: <ForgotPasswordCentered />,
          },
        ],
      },
    ],
  },

  /********   Admins Only Routes    **********/
  { // DASHBOARD
    name: "Dashboard",
    path: "/dashboards",
    icon: <MdHome className="text-inherit h-5 w-5" />,
    collapse: true,
    Role: "Admin",
    items: [
      {
        name: "Main Dashboard",
        layout: "/main",
        path: "/dashboards/admin",
        component: <AdminDashboard />,
      },
    ],
  },

  { // JOBS
    name: " Jobs",
    path: "/jobs",
    icon: <MdOutlineMapsHomeWork className="text-inherit h-5 w-5" />,
    collapse: true,
    Role: "Admin",
    items: [
      {
        name: "View Jobs",
        layout: "/main",
        path: "/jobs/view-all",
        component: <OverviewJob />,
      },
      {
        name: "Add a new Job",
        layout: "/main",
        path: "/jobs/new-job",
        component: <NewJob />,
      },
    ],
  },

  { // CUSTOMERS
    name: "Customers",
    path: "/customer",
    icon: <IoIosPeople className="text-inherit h-5 w-5" />,
    collapse: true,
    Role: "Admin",
    items: [
      {
        name: "View Customers",
        layout: "/main",
        path: "/customer/view-all",
        component: <ViewCustomers />,
      },
      {
        name: "Add a new Customer",
        layout: "/main",
        path: "/customer/new-customer",
        component: <NewCustomer />,
      },
    ],
  },

  { // SUPPLIERS
    name: "Suppliers",
    path: "/supplier",
    icon: <LuWarehouse className="text-inherit h-5 w-5" />,
    collapse: true,
    Role: "Admin",
    items: [
      {
        name: "View Suppliers",
        layout: "/main",
        path: "/supplier/view-suppliers",
        component: <OverviewSupplier />,
      },
      {
        name: "Add a new Supplier",
        layout: "/main",
        path: "/supplier/new-supplier",
        component: <NewSupplier />,
      },
    ],
  },

  { // INVOICE
    name: "Invoice",
    path: "/invoice",
    icon: <FaFileInvoice className="text-inherit h-5 w-5" />,
    collapse: true,
    Role: "Admin",
    items: [
      {
        name: "View Invoices",
        layout: "/main",
        path: "/invoice/view-invoices",
        component: <UsersOverview />,
      },
      {
        name: "Add a new Invoice",
        layout: "/main",
        path: "/invoice/new-invoice",
        component: <UsersReports />,
      },  
    ],
  },

  { // BANKING
    name: "Banking",
    path: "/banking",
    icon: <BiSolidBank className="text-inherit h-5 w-5" />,
    collapse: true,
    Role: "Admin",
    items: [
      {
        name: "Banking Dashboard",
        layout: "/main",
        path: "/banking/dashboard",
        component: <BankingDashboard />,
      }, 
      {
        name: "Bank Overview",
        layout: "/main",
        path: "/banking/overview",
        component: <BankingOverview />,
      },
      {
        name: "Bank CSV",
        layout: "/main",
        path: "/banking/bank-csv",
        component: <BankCSV />,
      },    
    ],
  },

  { // USERS
    name: "Users",
    path: "/admin/users",
    icon: <FaUsersGear className="text-inherit h-5 w-5" />,
    collapse: true,
    Role: "Admin",
    items: [
      {
        name: "View System Users",
        layout: "/main",
        path: "/view-users",
        component: <AdminDashboard />,
      },
      {
        name: "Add a new System User",
        layout: "/main",
        path: "/add-user",
        component: <AdminDashboard />,
      },  
      {
        name: "Hours Worked",
        layout: "/main",
        path: "/worked-hours",
        component: <AdminDashboard />,
      }, 
      {
        name: "Payroll",
        layout: "/main",
        path: "/payroll",
        component: <AdminDashboard />,
      }, 
      {
        name: "Holiday Requests",
        layout: "/main",
        path: "/holiday-requests",
        component: <AdminDashboard />,
      },  
    ],
  },

  { // SYSTEM
    name: "System Settings",
    path: "/system",
    icon: <MdOutlineDisplaySettings className="text-inherit h-5 w-5" />,
    collapse: true,
    Role: "Admin",
    items: [
      {
        name: "System Overview",
        layout: "/main",
        path: "/system-overview/:id",
        component: <AdminDashboard />,
      },   
      {
        name: "Matching Rules",
        layout: "/main",
        path: "/system-settings/auto-matcher/:id",
        component: <AutoMatch />,
      }, 
      {
        name: "System Settings",
        layout: "/main",
        path: "/system-settings/:id",
        component: <AdminDashboard />,
      }, 
      {
        name: "Billing",
        layout: "/main",
        path: "/billing/:id",
        component: <AdminDashboard />,
      },      
    ],
  },
  
  /** Private Admin Routes */
    
    { //Jobs
      name: "AdminJobs",
      path: "/jobs",
      icon: <MdHome className="text-inherit h-5 w-5" />,
      collapse: true,
      Role: "none",
      items: [
        {
          name: "Current Job",
          layout: "/main",
          path: "/jobs/view-job/:id",
          component: <SettingsJob />,
        },
      ],
    },
    
    {//Customers
      name: "Admin Customers",
      path: "/customers",
      icon: <MdHome className="text-inherit h-5 w-5" />,
      collapse: true,
      Role: "none",
      items: [
        {
          name: "CurrentCustomer",
          layout: "/main",
          path: "/customer/view-customer/:id",
          component: <DashboardsDefault />,
        },
      ],
    },

    {//Suppliers
      name: "Admin suppliers",
      path: "/supplier",
      icon: <MdHome className="text-inherit h-5 w-5" />,
      collapse: true,
      Role: "none",
      items: [
        {
          name: "CurrentCustomer",
          layout: "/main",
          path: "/supplier/view-supplier/:id",
          component: <SuppliersSettings />,
        },
      ],
    },

    {//Invoice
      name: "Admin Invoice",
      path: "/invoice",
      icon: <MdHome className="text-inherit h-5 w-5" />,
      collapse: true,
      Role: "none",
      items: [
        {
          name: "Currentinvoice",
          layout: "/admin",
          path: "/dashboards/default",
          component: <DashboardsDefault />,
        },
      ],
    },
  
    {//Banking
      name: "Admin Banking",
      path: "/admin",
      icon: <MdHome className="text-inherit h-5 w-5" />,
      collapse: true,
      Role: "none",
      items: [
        {
          name: "currentCSV",
          layout: "/main",
          path: "/banking/view/:id",
          component: <BankCSV />,
        },
        {
          name: "New Statement",
          layout: "/main",
          path: "/banking/add",
          component: <NewBankStaments />,
        },
      ],
    },

    {
      /** System Settings **/
      name: "Admin System Settings",
      path: "/system",
      icon: <MdHome className="text-inherit h-5 w-5" />,
      collapse: true,
      Role: "none",
      items: [
        {
          name: "AutoMatcheUsingCSV",
          layout: "/main",
          path: "/system-settings/auto-matcher/match-for-me",
          component: <NewAutoMatch />
        }
      ]
    },
  

/********************************************************/


    /** Supervisor Only Routes */

    {
      name: "Supervisor",
      path: "/users",
      icon: <MdHome className="text-inherit h-5 w-5" />,
      collapse: true,
      Role: "Supervisor",
      items: [
        {
          name: "Main Dashboard",
          layout: "/admin",
          path: "/dashboards/default",
          component: <DashboardsDefault />,
        },
        {
          name: "RTL",
          layout: "/rtl",
          path: "/dashboards/rtl",
          component: <DashboardsRTLDefault />,
        },
      ],
    },

      /** Normal Users Only Routes */

  {
    name: "User",
    path: "/users",
    icon: <MdHome className="text-inherit h-5 w-5" />,
    collapse: true,
    Role: "users",
    items: [
      {
        name: "Main Dashboard",
        layout: "/admin",
        path: "/dashboards/default",
        component: <DashboardsDefault />,
      },
      {
        name: "RTL",
        layout: "/rtl",
        path: "/dashboards/rtl",
        component: <DashboardsRTLDefault />,
      },
    ],
  },


  /** All Users - Visible to everyone. */
  { // USER PROFILE
    name: " My Account",
    path: "/profile",
    icon: <FaUser className="text-inherit h-5 w-5" />,
    collapse: true,
    Role: "all",
    items: [
      {
        name: "My Profile",
        layout: "/main",
        path: "/profile/my-profile/",
        component: <ProfileOverview />,
      },
      {
        name: "Profile Settings",
        layout: "/main",
        path: "/profile/settings/",
        component: <ProfileSettings />,
      },
    ],
  },



];
export default routes;
