/**
 * @name: Confirmation
 * @description: Allows the user to confirm if the system has correctly identified the correct job and supplier. If not then the user will ba able to manually assign it.  
 * @author: Fatlum Hasaj
 */

/*** TODO
 *  This page will be a table in which all the different 
 */

import React, { useState  } from "react";
import { useNavigate } from "react-router-dom";

import Button from "components/fields/ButtonField";
import InputField from "components/fields/InputField";

import { toast } from "react-toastify";



const Profile = (props:any) => {
  const {Data1, Data2, handleClick} = props;

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function HandleBackClick(name: any) {  handleClick(name);  }

  const submitData = async () => { 
    

  };

  
  return (
    <div className="h-full w-full rounded-md px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        CSV Uploaded and data parsed.
      </h4>
      {/* inputs */}

      <p>
        The CSV files have been successfully uploaded and parsed so relivent data will be avialable to you 
      </p>
      
    </div>
  );
};

export default Profile;
