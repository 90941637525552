/**
 * @name: Index ( Auto matcher using CSV )
 * @description: Index page in which a CSV file will be uploaded and then all the description will be taken from it . This is going to be done on the users end as this will be quick.
 * @author: Fatlum Hasaj
 *
 */
import { useState } from 'react';
import Stepper from './components/Stepper';
import { UseContextProvider } from './contexts/StepperContext';
import UploadCSV from './components/steps/UploadCSV';
import Processing from './components/steps/Processing';
import Confirmation from './components/steps/Confirmation';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import BackendRouts from 'api/BackendRoutes/BackendRoutes';
import secureLocalStorage from 'react-secure-storage';
import Card from 'components/card';

const NewUser = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [FirstStage, setFistStage] = useState<any>([]);
  const [SecondStage, setSecondStage] = useState<boolean>();
  const [data, setData] = useState<any>([]);
  const [progress, setProgress] = useState<number>(0);
  const axios = useAxiosPrivate();

  const HandleStepOneData = (data: any) => {
    // Send data back to the backend and then get the response of confirmation and then set the progress bar.

    console.log("Data from Upload: ", data);

    var config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: function (progressEvent: any) {
        var percentCompleted = Math.round(
          (progressEvent.load * 100) / progressEvent.total
        );
        console.log(percentCompleted);
        setProgress(percentCompleted);
      },
    };

    axios
      .post(
        'http://localhost:3500/api/file-processing/autoMatch/file/64a87826b5284f2c3cc5aef0',
        //BackendRouts.AutoMatchFile + secureLocalStorage.getItem('cid'),
        {
          file: data
        },
        config
      )
      .then((Response) => {
        // if (progress === 100) {
        //   handleClick('next');
        // }
        // setSecondStage(data);

        console.log(Response);
      });
  };

  const handleStepTwoData = (data: any) => {
    /**
     * Once there is a response from the server move over to step 3
     */
  };

  const handleStepThreeData = (data: any) => {
    /**
     * 1. Show a table of the transaction Descriptions
     * 2. Show if the its a supplier or Employee/Contractor
     * 3. Once Saved send to the backend and add to a new table in which these will saved to so when matching again it will be faster.
     * 4. Send back to the main auto matcher page
     */
  };

  const steps = [
    { stepNo: 1, name: 'Upload CSV' },
    { stepNo: 2, name: 'Processing' },
    { stepNo: 3, name: 'Confirmation' },
  ];
  const displayStep = (step: {
    stepNo: number;
    name: string;
    highlighted?: boolean;
    selected?: boolean;
    completed?: boolean;
  }) => {
    switch (step.stepNo) {
      case 1:
        return (
          <UploadCSV
            sendDataToStepOne={HandleStepOneData}
            handleClick={handleClick}
            currentStep={currentStep}
            sendPrecent={progress}
            steps={steps}
          />
        );
      case 2:
        return (
          <Processing
            dataComplete={handleStepTwoData}
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps}
          />
        );
      case 3:
        return (
          <Confirmation
            Data={data}
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps}
          />
        );
      default:
    }
  };

  const handleClick = (direction: string) => {
    let newStep = currentStep;

    direction === 'next' ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };
  return (
    <div className="mt-3 h-full w-full">
      <div className="h-[350px] w-full rounded-md bg-gradient-to-br from-brand-400 to-brand-600 md:h-[390px]" />
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
        <div className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]">
          <Stepper
            action={setCurrentStep}
            steps={steps}
            currentStep={currentStep}
          />
        </div>
        <Card extra={'h-full mx-auto pb-3'}>
          <div className="rounded-[20px]">
            <UseContextProvider>
              {displayStep(steps[currentStep - 1])}
            </UseContextProvider>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default NewUser;
